@import '/styles/vars';

html {
    .christmas {
        transform: scale(1.6);
    }
    .pulse {
        transform: scale(1.4);
        animation: pulse 1s infinite;
    }
    .egg {
        transform: translate3d(0, 0, 0) scale(1.6);
        // perspective: 750px;
        // transform-style: preserve-3d;
        // svg {
        //     left: 0em;
        //     position: absolute;
        //     transform-origin: 50% 0.5em;
        //     // transform: scale(1.4);
        //     animation: eggRotate 3s infinite cubic-bezier(0.5, 0.15, 0.5, 0.85), eggTop 3s infinite;
        // }
    }
    .headerActions {
        flex-shrink: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 298px; // 238px;
        margin: 0 0 0 auto;
        .icon {
            margin-left: $gap36;
            color: $color_dark;
            svg {
                stroke-width: 1.8;
            }
            &:hover {
                color: $color_yellow;
            }
        }

        .iconActive {
            color: $color_accent !important;
        }

        @include desktop {
            &.alt {
                .icon {
                    color: #fff;
                    &:hover {
                        color: $color_accent;
                    }
                }
            }
        }

        @include tablet {
            width: auto;
        }

        @include mobile {
            .icon {
                margin-left: 10px;
            }
            > .icon:first-child {
                margin: 0;
            }
        }
    }
}

@keyframes eggRotate {
    to {
        transform: rotateZ(360deg);
    }
}

@keyframes eggTop {
    0%,
    100% {
        top: 0em;
        animation-timing-function: cubic-bezier(0.75, 0, 0.5, 0.5);
    }
    35%,
    65% {
        top: 0.2em;
        animation-timing-function: cubic-bezier(0.5, 0.5, 0.25, 1);
    }
}

@keyframes pulse {
    10% {
        transform: scale(1.8);
    }
}
