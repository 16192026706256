@import '/styles/vars';

.promo {
    position: relative;
    width: 100vw;
    width: var(--vw100);
    user-select: none;
    left: 50%;
    margin-left: -50vw;
    background: $color_yellow;
    padding: 12px $gap24;
    position: relative;
    z-index: 5;
    color: $color_dark;
    text-align: center;
    .mini {
        cursor: pointer;
        width: 12px;
        height: 12px;
        display: inline-block;
        position: relative;
        &:before {
            content: '';
            width: 10px;
            height: 10px;
            left: 1px;
            top: 4px;
            transform: rotate(-45deg);
            position: absolute;
            border-right: 2px solid currentColor;
            border-top: 2px solid currentColor;
        }
    }
    i {
        font-style: normal;
        cursor: pointer;
        margin-left: 12px;
    }
    .row {
        margin: 0 12px;
        white-space: nowrap;
    }
    .link {
        margin: 0 24px 0 6px;
        white-space: nowrap;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    b {
        font-weight: $bold;
        font-size: $font18;
        margin: 0 6px 0 3px;
    }
    &.black {
        background: #161e1b;
        color: $color_light;
        b {
            color: $color_light;
        }
    }
    @include tablet {
        top: 78px;
        &.alt {
            top: 0;
        }
    }
    @include mobile {
        top: 96px;
        padding: 9px;
        display: block;
        text-align: left;
        margin-bottom: 10px;
        .row {
            line-height: 15px;
            display: inline-block;
            margin: 0 6px;
            font-size: $font14;
        }
        .link {
            font-size: $font14;
        }
        .mini {
            display: none;
        }
        // .x {
        //     position: absolute;
        //     top: 50%;
        //     margin-top: -15px;
        //     height: 30px;
        //     line-height: 30px;
        //     text-align: center;
        //     width: 30px;
        //     right: 0;
        // }
        b {
            font-size: $font16;
        }
        &.alt {
            top: 0;
        }
    }
    @include desktop {
        &.minimized {
            position: absolute;
            z-index: 20;
            font-size: 0;
            width: auto;
            margin: auto;
            cursor: pointer;
            margin-left: -75px;
            width: 150px;
            padding: 3px 24px;
            border-radius: 0 0 $radius1 $radius1;
            .mini {
                display: none;
            }
            .row,
            .link {
                margin: 0;
            }
            b {
                font-size: $font14;
                margin: 0;
                position: relative;
                left: -3px;
            }
        }
    }
}
