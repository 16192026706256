@import '/styles/vars';

html {
    .groupName {
        //        @include middle {
        @media only screen and (max-width: 1200px) {
            text-transform: capitalize;
            i {
                display: none;
            }
        }
        @include tablet {
            text-transform: none;
            i {
                display: inline;
            }
        }
    }

    .relative {
        position: relative;
    }

    .taxonomyList {
        position: absolute;
        left: -$gap36;
        top: 100%;
        line-height: $gap30;
        margin-top: 0;
        display: flex;
        display: none;
        z-index: 2;
        &:after {
            content: '';
            position: absolute;
            width: 15px;
            height: 15px;
            transform: rotate(-45deg);
            border-top: 1px dashed $color_border;
            border-right: 1px dashed $color_border;
            background: $color_light;
            top: -8px;
            left: 44px;
        }
        &:before {
            box-shadow: 0 30px 60px 0 rgba(21, 55, 56, 0.1);
            // box-shadow: 0 0 60px 0 rgba(21, 55, 56, 0.2);
            content: '';
            position: absolute;
            width: 200vw;
            left: 50%;
            margin-left: -100vw;
            margin-right: var(--vw100);
            // -webkit-backdrop-filter: blur(4px);
            // backdrop-filter: blur(4px);
            // background: rgba(255, 255, 255, 0.95);
            background: $color_light;
            height: 100%;
            border-top: 1px dashed $color_border;
            // border-bottom: $border;
        }
        a {
            padding: 0px 36px;
            display: flex;
            width: 100%;
            white-space: nowrap;
            justify-content: space-between;
            align-items: center;
            position: relative;
        }
    }

    .taxonomyMainlist {
        padding: $gap24 0;
        min-width: 180px;
    }

    .taxonomySublist {
        margin: $gap24 0;
        border-left: $dashed;
        display: none;
        position: relative;
        a:hover {
            color: $color_green;
        }
        @include mobile {
            margin: 0;
        }
    }

    .count {
        margin: 0 0 0 $gap36;
        color: $color_faded;
        opacity: 0.6;
        font-weight: $normal;
    }

    .back,
    .next {
        top: 0; //50%;
        height: $gap48;
        width: $gap48;
        right: 0;
        margin-top: 3px;
        //margin-top: -24px;
        position: absolute;
        background-image: url(/back.svg);
        transform: rotate(180deg);
        background-size: 28pxd;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        cursor: pointer;
        @include desktop {
            display: none;
        }
    }

    .next {
        top: -6px;
    }

    .back {
        transform: rotate(0);
        right: 36px;
    }

    .activeGroup {
        @include desktop {
            display: flex;
        }
    }

    .activeGroupMobile {
        @include tablet {
            display: block;
        }
    }

    .activeList {
        @include desktop {
            display: block;
        }
    }

    .activeMobileList {
        @include tablet {
            display: block;
        }
    }

    .activeLink {
        @include desktop {
            color: $color_green;
            position: relative;
            &::before {
                background-image: url(/caret-right.svg);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                content: '';
                width: 14px;
                height: 14px;
                position: absolute;
                right: 100%;
                margin-right: 4px;
                top: 50%;
                margin-top: -7px;
            }
        }
    }

    @include desktop {
        .alt.taxonomyList {
            background: rgba(40, 44, 53, 0.95);
            border-radius: $radius3;
            &:after {
                border: 0;
                background: rgba(40, 44, 53, 0.95);
            }
            &::before {
                display: none;
            }
            .taxonomySublist {
                border-color: rgba(255, 255, 255, 0.08);
            }
        }
    }

    @include tablet {
        .taxonomyList {
            width: 100%;
            max-width: 320px;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            padding: 54px 0;
            background: #fff;
            z-index: 1;
            margin: 0;
            border-radius: 0;
            border: 0;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            &:before {
                display: none;
            }
            .taxonomyMainlist {
                width: 100%;
                padding: 0;
                position: static;
            }
            .count {
                font-size: inherit;
                margin-left: 15px;
                opacity: 0.5;
            }
            .taxonomySublist {
                width: 100%;
                max-width: 320px;
                height: 100vh;
                position: fixed;
                top: 0;
                left: 0;
                padding: $gap72 0;
                background: #fff;
                z-index: 2;
                overflow: auto;
                -webkit-overflow-scrolling: touch;
            }
        }
    }

    @include mobile {
        .taxonomyList {
            .taxonomySublist {
                border: 0;
                padding: 54px 0;
            }
        }
    }
}
