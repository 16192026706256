@import '/styles/vars';

.button {
    background: #fff;
    flex-shrink: 0;
    line-height: 18px;
    align-items: center;
    display: flex;
    height: 48px;
    font-size: $font14;
    cursor: pointer;
    padding: 0 12px;
    border-radius: $radius3;
    white-space: nowrap;
    svg {
        width: 24px;
        height: 24px;
        margin-left: 12px;
    }
    @include tablet {
        div {
            display: none;
        }
        svg {
            margin: 0;
        }
    }
    @include mobile {
        height: 36px;
        margin-left: 6px !important;
    }
}
