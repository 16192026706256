@import '/styles/vars';

html {
    .dot {
        position: absolute;
        transition: fill $ease;
        width: 10px;
        height: 10px;
        fill: $color_accent;
        top: -2px;
        right: -5px;
        padding: 0 !important;
    }

    .dropdown {
        padding-top: $gap24;
        width: 450px;
        opacity: 0;
        pointer-events: none;
        transform: translate3d(0, 15px, 0);
        transition: opacity $ease, transform $ease;
        position: absolute;
        right: 0;
        @include mobile {
            display: none;
        }
    }

    .content {
        box-shadow: $shadow;
        transform: translate3d(0, 0, 0);
        border: $border;
        border-radius: $radius3;
        background: #fff;
        max-height: 60vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    .items {
        transform: translate3d(0, 0, 0);
        padding: $gap24 $gap36 0 $gap36;
        overflow: auto;
    }

    .item {
        display: flex;
        align-items: center;
        margin-bottom: $gap24;
        display: flex;
        align-items: center;
        width: 100%;
    }

    .image {
        flex-shrink: 0;
        width: $gap72;
        height: $gap72;
        border-radius: $radius2;
        background: $color_light;
        overflow: hidden;
        margin: 0 $gap24 0 0;
        transform: translate3d(0, 0, 0);
        img {
            mix-blend-mode: multiply;
        }
    }

    .text {
        margin-right: $gap24;
        small {
            font-size: 12px;
            color: $color_faded;
            display: block;
        }
    }

    .price {
        color: $color_faded;
    }

    .total {
        font-size: $font18;
        color: $color_green;
        font-weight: $bold;
    }

    .bottom {
        flex-shrink: 0;
        align-items: center;
        display: flex;
        justify-content: space-between;
        border-top: $border;
        padding: $gap24 0;
        margin: 0 $gap36;
    }

    .btn {
        width: 50%;
    }

    .x {
        margin: 0 0 0 auto;
    }

    .cart {
        position: relative;
        &:hover {
            .dropdown {
                transform: translate3d(0, 0, 0);
                opacity: 1;
                pointer-events: all;
            }
        }
    }
}
