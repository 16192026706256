@import '/styles/vars';

.product {
    list-style: none;
    cursor: pointer;
    margin: 12px 0;
    padding: 0 $gap36 0 $gap24;
    width: 100%;
    display: flex;
    align-items: center;
    pointer-events: all;
    position: relative;
    &:before {
        content: '';
        background: $color_light;
        position: absolute;
        left: 0;
        top: -6px;
        bottom: -6px;
        width: 100%;
        opacity: 0;
    }
    &:hover:before {
        opacity: 1;
    }
}

.fullLink {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.image {
    width: $gap60;
    height: $gap60;
    overflow: hidden;
    border-radius: $radiusImg;
    background: $color_light;
    position: relative;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate3d(0, 0, 0);
    img {
        mix-blend-mode: multiply;
    }
    > div, > span {
        width: 100% !important;
        height: 100% !important;
    }
    .innerImage {
        width: 42px;
        height: 42px;
        position: relative;
    }
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 $gap12;
    h3 {
        font-size: $font14;
        font-weight: $normal;
        color: $color_dark;
        // margin: 0 0 3px 0;
        line-height: 1.15;
    }
    .brand,
    a,
    small {
        align-self: flex-start;
        font-size: $font13;
        color: $color_faded;
    }
}

.right {
    display: flex;
    width: 100%;
    position: relative;
}

html {
    .price {
        margin: 0 0 0 auto;
        //color: $color_green;
        font-weight: $bold;
        font-size: $font14;
    }
}

@include mobile {
    .product {
        padding: 0;
        margin: $gap12 0;
        &:before {
            display: none;
        }
    }
    .right {
        flex-direction: column;
        margin: 0 0 0 $gap18;
        .text {
            order: 2;
            margin: 0;
        }
        .price {
            margin: 0;
        }
        .brand {
            display: none;
        }
    }
}
