@import '/styles/vars';

html {
    .paper {
        position: absolute;
        width: 100%;
        top: 100%;
        height: 1.451vw;
        margin-top: -2px;
        pointer-events: none;
    }
    .headerContainer {
        position: relative;
        width: 100vw;
        width: var(--vw100);
        left: 50%;
        margin-left: -50vw;
    }
    .header {
        color: $color_dark;
        z-index: 10;
        padding-top: $gap18;
        width: 100%;
        top: 0;
        position: absolute;
        left: 0;
        @include headerBackground;
        input {
            box-shadow: none !important;
            // border-radius: 24px;
            + button {
                right: 6px !important;
            }
        }
    }

    .primary {
        input {
            background: #fff;
            border: 0;
            // background: $color_light !important;
        }
    }

    .wrap {
        max-width: $wrap;
        position: relative;
        margin: 0 auto;
        padding: 0 $gap36;
    }

    .bg {
        padding-top: 126px;
        margin: 0 0 $headerDesktopMargin 0;
        @include tablet {
            padding-top: 84px;
        }
    }

    .minimal {
        .bg {
            padding-top: 84px;
        }
    }

    .logo {
        display: block;
        position: relative;
        // transform: translate(0, -3%);
        svg {
            vertical-align: top;
            width: 165px;
            height: $gap48;
            fill: $color_dark;
        }
    }

    .top {
        display: flex;
        width: 100%;
        z-index: 1;
        position: relative;
        // justify-content: space-between;
        padding-bottom: $gap18;
        align-items: center;
    }

    .left {
        //width: 238px;
        margin-right: 30px;
        flex-shrink: 0;
    }

    .middle {
        width: 100%;
        position: relative;
        display: flex;
        > * + * {
            margin-left: $gap18;
        }
    }

    // .mobileLogo {
    //     display: none;
    // }

    // @include desktop {
    //     body .alt {
    //         .bg {
    //             display: none;
    //         }
    //         .header {
    //             &:after,
    //             &:before {
    //                 display: none;
    //             }
    //         }
    //         .logo {
    //             svg {
    //                 fill: $color_white;
    //             }
    //         }
    //         input {
    //             border: 0;
    //             background: rgba(40, 44, 53, 0.4);
    //             color: #fff !important;
    //             text-indent: 0 !important;
    //             box-shadow: none !important;
    //             &::placeholder {
    //                 color: $color_white;
    //             }
    //             &:focus {
    //                 &::placeholder {
    //                     color: transparent;
    //                 }
    //             }
    //         }
    //         .middle {
    //             button {
    //                 svg {
    //                     transition: color $ease, opacity $ease;
    //                     color: rgba(255, 255, 255, 0.9);
    //                 }
    //                 &:hover {
    //                     svg {
    //                         color: $color_accent;
    //                         opacity: 1;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }

    @include tablet {
        .full {
            .header {
                position: fixed;
            }
        }
        .logo {
            // margin-right: $gap36;
            svg {
                height: 42px;
                width: 138px;
            }
        }
        .left {
            width: auto;
        }
        .wrap {
            padding: 0 $gap36;
        }
        .menu {
            margin-right: 30px;
        }
        .bg {
            padding-top: 78px;
        }
        .minimal {
            .bg {
                padding-top: 78px;
            }
        }
        .middle {
            margin: -3px 0;
        }
    }

    @include mobile {
        body .header {
            padding: 12px 0;
            input {
                height: $gap36;
                transition: none;
                padding: 0 15px 2px 15px;
                &::placeholder {
                    font-size: $font14;
                    transition: none;
                }
                + button {
                    width: $gap36;
                    height: $gap36;
                    padding: 6px;
                }
            }
        }
        .wrap {
            padding: 0 $gapM;
        }
        .top {
            padding: 0;
            flex-wrap: wrap;
        }
        .menu {
            margin-right: 15px;
        }
        .left {
            margin: 0;
        }
        .logo {
            height: 30px;
            display: flex;
            align-items: center;
            svg {
                height: 34px;
                width: 116px;
            }

            // svg {
            //     display: none;
            // }
            // .mobileLogo {
            //     transform: translate(0, 0);
            //     height: 30px;
            //     width: 168px;
            //     display: block;
            // }
        }
        .middle {
            width: 100%;
            order: 3;
            margin: 9px 0 -3px 0;
        }
        .bg {
            padding-top: 96px;
            margin: 0 0 $headerMobileMargin 0;
        }
        .minimal {
            .bg {
                padding-top: 54px;
            }
        }
    }

    @media (max-width: 344px) {
        .logo {
            height: 24px;
            display: flex;
            align-items: center;
            margin-left: -6px;
            svg {
                height: 24px;
                width: 96px;
            }
        }
    }

    // @media (max-width: 374px) {
    //     .logo {
    //         .mobileLogo {
    //             width: 138px;
    //             height: 22px;
    //         }
    //     }
    // }
}
