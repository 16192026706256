@import '/styles/vars';

html {
    .container {
        width: 100%;
        position: relative;
    }

    .input {
        margin: 0 !important;
        input {
            padding-right: 78px;
            &::placeholder {
                color: $color_faded;
            }
            @include mobile {
                height: 36px !important;
            }
        }
    }

    .content {
        background: #fff;
        min-width: 720px;
        border-radius: $radius3;
        display: flex;
        width: 100%;
        box-shadow: $shadow;
        position: absolute;
        top: 100%;
        width: 100%;
        margin: $gap12 0 0 0;
        u {
            text-decoration: none;
        }
        // &:before {
        //     content: '';
        //     border-radius: $radius3;
        //     border: $transparent_border;
        //     position: absolute;
        //     width: 100%;
        //     height: 100%;
        //     left: -1px;
        //     top: -1px;
        //     box-sizing: content-box;
        // }
    }

    .left {
        width: 45%;
        border-right: $dashed;
        @include mobile {
            li {
                display: none;
            }
            li:nth-child(1),
            li:nth-child(2),
            li:nth-child(3),
            li:nth-child(4),
            li:nth-child(5),
            li:nth-child(6),
            li:nth-child(7) {
                display: block;
            }

        }
    }

    .right {
        width: 55%;
        padding: 0 0 12px 0;
    }

    .suggestion {
        line-height: 1.1;
        padding: 5px $gap24 5px $gap36;
    }

    .label {
        font-weight: $bold;
        padding: 12px $gap24 0 $gap24;
    }

    .list {
        position: relative;
        margin: 0;
        padding: 0;
        max-height: 80vh;
        @include scroll;
    }

    .suggestions {
        padding: $gap24 - 3px 0;
    }

    .all {
        display: block;
        margin-top: -8px;
        padding: 0 $gap24 15px $gap36;
        cursor: pointer;
        font-weight: $bold;
        color: $color_dark;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }

    .icon {
        position: absolute !important;
        right: 3px;
        top: 0;
        background: transparent;
        &:hover {
            background: transparent;
            color: $color_accent;
        }
    }

    .xIcon {
        margin-right: 36px;
        svg {
            padding: 3px;
        }
    }

    @include desktop() {
        .isClosed {
            display: none;
        }
    }

    @include tablet {
        .content {
            left: 50%;
            transform: translate(-50%, 0);
        }
    }

    @include mobile {
        .content {
            @include scroll;
            position: fixed;
            top: 92px;
            margin: auto;
            left: 0;
            bottom: 0;
            display: block;
            border-radius: 0;
            box-shadow: none;
            padding: $gap12 $gap18;
            min-width: 0;
            left: 0;
            transform: none;
            &:before {
                display: none;
            }
            .left,
            .right {
                width: auto;
                border: 0;
            }
            .suggestion {
                padding: 3px 0;
            }
            .all {
                padding: 3px 0;
            }
            .list {
                padding: 0;
                max-height: initial;
            }
        }
        .label {
            padding: 12px 0 0 0;
        }
        .icon {
            width: 36px;
            height: 36px;
            padding: 3px;
        }
        .xIcon {
            margin-right: 27px;
            svg {
                padding: 3px;
            }
        }
    }
}
