@import '/styles/vars';

html {
    .taxonomies {
        display: flex;
        justify-content: space-between;
        user-select: none;
        font-size: $font14;
        color: $color_dark;
        border-top: 1px dashed $color_border;
        i {
            font-style: normal;
            white-space: pre;
        }
        > * > a {
        }
        a {
            font-weight: $medium;
            &:hover {
                text-decoration: none;
            }
        }
        > * {
            position: relative;
            > a {
                line-height: 22px;
                display: block;
                font-weight: 600;
                // font-size: 16px;
                padding: 10px $gap24 10px 0;
                // font-feature-settings: initial;
                // font-variant: small-caps;
                letter-spacing: 0.02em;
                span {
                }
                &:hover {
                    color: $color_green;
                }
            }
            &:last-child {
                > a {
                    padding-right: 0;
                }
            }
        }
    }

    .mobileShow {
        opacity: 1;
        pointer-events: all;
    }

    .listName {
        white-space: nowrap;
        padding: 0 $gap72 0 $gap18;
        line-height: 54px;
        font-weight: $bold;
        overflow: hidden;
        text-overflow: ellipsis;
        top: 0;
        font-size: $font18;
        position: absolute;
        left: 0;
        width: 100%;
        color: $color_green;
    }

    .icon {
        position: absolute !important;
        right: 0;
        width: $gap48;
        height: 54px;
        z-index: 3;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color_medium !important;
        svg {
            width: 28px;
            height: 28px;
        }
    }

    @include desktop {
        .alt {
            color: $color_white;
        }
    }

    @include tablet {
        .taxonomies {
            width: 90%;
            max-width: 320px;
            height: 100vh;
            position: fixed;
            border: 0;
            background: #fff;
            left: 0;
            top: 0;
            color: $color_dark;
            margin: 0;
            padding: 54px 0;
            display: block;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            z-index: 20;
            transform: translate3d(-100%, 0, 0);
            transition: transform $ease;
            a {
                display: flex;
                line-height: 1.5;
                position: relative;
                white-space: normal;
                color: $color_dark;
                padding: 9px 48px 9px 18px;
            }
        }
        .mobileShow {
            transform: translate3d(0, 0, 0);
        }
        body .backdropShow {
            opacity: 1;
            pointer-events: all;
        }
    }
}
